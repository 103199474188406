<template>
  <div>
    <Reviewdetalls />
  </div>
</template>

<script>
import Reviewdetalls from "@/components/Reviewdetalls/Reviewdetalls.vue";
export default {
  name: "",
  components: {
    Reviewdetalls,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
</style>